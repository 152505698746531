<template>
  <div>
    <div class="sub-header-container">
      <div class="sub-header">
        <ets-button text="Añadir nuevo usuario" mode="main" @click="addUser" />
      </div>
    </div>
    <table class="users-container">
      <thead>
        <tr>
          <th>Apellidos, Nombre</th>
          <th>Usuario</th>
          <th>Email</th>
          <th>Empresa</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.userId">
          <td>{{ user.fullName }}</td>
          <td>{{ user.loginName }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.companyName }}</td>
          <td>
            <ets-button-group>
              <ets-button text="Editar" @click="editUser(user.userId)" />
              <ets-button text="Eliminar" @click="deleteUserConfirm(user.userId, true)" />
            </ets-button-group>
          </td>
        </tr>
      </tbody>
    </table>
    <ets-popup ref="addUserPopup" title="Nuevo usuario" v-if="showAddUserPopup" @accept="insertUser" @close="closeAddUser">
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Login Name</label>
          <ets-input v-model="newLoginName" class="popup-input" />
          <label class="popup-label">Email</label>
          <ets-input v-model="newEmail" class="popup-input" />
          <label class="popup-label">Empresa</label>
          <DropDown :options="companyOptions()" @item-selected="handleItemSelected" class="form-input popup-input" />
          <label class="popup-label">Contraseña</label>
          <ets-input v-model="newPassword" class="popup-input" />
          <label class="popup-label">Nombre</label>
          <ets-input v-model="newName" class="popup-input" />
          <label class="popup-label">Apellidos</label>
          <ets-input v-model="newSurnames" class="popup-input" />
          <div class="period-dates">
            <p class="filter-text">Fecha incorporación</p>
            <v-date-picker
              v-model="newEntryDate"
              :model-config="calendarEntryDate.modelConfig"
              mode="date"
              :locale="calendarEntryDate.calendarLocale"
              :disabled-dates="calendarEntryDate.disabledDates"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" class="popup-input-date" />
              </template>
            </v-date-picker>
          </div>
          <label class="popup-label">Permisos</label>
          <ets-checkbox-radio
            style="margin-top: 1.5rem"
            v-model="newIsAdminHolidays"
            :checked="false"
            boolean
            text="Administrar vacaciones"
          />
          <ets-checkbox-radio v-model="newIsSAHolidays" :checked="false" boolean text="Súper administador de vacaciones" />
          <ets-checkbox-radio v-model="newIsAdminCatering" :checked="false" boolean text="Admininstrar catering" />
          <ets-checkbox-radio v-model="newIsSACatering" :checked="false" boolean text="Súper administrador de catering" />
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
      </div>
    </ets-popup>
    <ets-popup ref="editUserPopup" :title="selectedUser.email" v-if="showEditUserPopup" @accept="updateUser" @close="closeEditUser">
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Usuario</label>
          <ets-input v-model="newLoginName" class="popup-input" />
          <label class="popup-label">Nombre</label>
          <ets-input v-model="newName" class="popup-input" />
          <label class="popup-label">Apellidos</label>
          <ets-input v-model="newSurnames" class="popup-input" />
          <label class="popup-label">Email</label>
          <ets-input v-model="newEmail" class="popup-input" />
          <div class="period-dates">
            <p class="filter-text">Fecha de entrada</p>
            <v-date-picker
              v-model="newEntryDate"
              :model-config="calendarEntryDate.modelConfig"
              mode="date"
              :locale="calendarEntryDate.calendarLocale"
              :disabled-dates="calendarEntryDate.disabledDates"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" class="popup-input-date" />
              </template>
            </v-date-picker>
          </div>
          <div class="period-dates">
            <p class="filter-text">Fecha de salida</p>
            <v-date-picker
              v-model="newOutDate"
              :model-config="calendarOutDate.modelConfig"
              mode="date"
              :locale="calendarOutDate.calendarLocale"
              :disabled-dates="calendarOutDate.disabledDates"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" class="popup-input-date" />
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
    </ets-popup>
    <ets-popup v-if="this.showConfirmationDelete" title="¿Estás seguro?" @close="deleteUserConfirm(true)" @accept="deleteUser()">
      <div>¿Quieres eliminar al usuario?</div>
    </ets-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
import DropDown from "@/components/DropDown.vue";
import companies from "@/logic/companies";
import tokens from "@/logic/tokens";
import users from "@/logic/users";

dayjs.extend(dayjsBusinessDays);
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    DropDown
  },
  data: () => ({
    users: [],
    loggedUser: null,
    showAddUserPopup: false,
    showEditUserPopup: false,
    selectedUser: null,
    newLoginName: null,
    newEmail: null,
    newPassword: null,
    newCompanyId: null,
    newName: null,
    newSurnames: null,
    newIsAdminHolidays: false,
    newIsSAHolidays: false,
    newIsAdminCatering: false,
    newIsSACatering: false,
    newEntryDate: null,
    newOutDate: null,
    calendarEntryDate: {
      calendarLocale: { firstDayOfWeek: 2, masks: { input: "DD/MM/YYYY", weekdays: "W" } },
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY"
      },
      disabledDates: { weekdays: [1, 7] },
      beginDate: null,
      endDate: null
    },
    calendarOutDate: {
      calendarLocale: { firstDayOfWeek: 2, masks: { input: "DD/MM/YYYY", weekdays: "W" } },
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY"
      },
      disabledDates: { weekdays: [1, 7] },
      beginDate: null,
      endDate: null
    },
    errorMessage: null,
    userIdToDelete: null,
    showConfirmationDelete: false
  }),
  created() {
    this.fetchCompanies();
  },
  mounted() {
    this.getData();
    this.calendarEntryDate.beginDate = this.firstCurrentYearBusinessDay;
    this.calendarEntryDate.endDate = this.today;
    this.calendarOutDate.beginDate = this.firstCurrentYearBusinessDay;
    this.calendarOutDate.endDate = this.today;
  },
  methods: {
    normalize(name) {
      return name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    async getData() {
      await this.checkUser();
      let response = await companies.getCompanyUsers(this.loggedUser.companyId, "users");
      let usersInfo = response.data;

      let users_partial = [];
      await Promise.all(
        usersInfo.map(async user => {
          const company = await companies.getCompany(user.companyId);
          const newUser = Object.assign(user, { companyName: company.data[0].name });
          newUser.fullName = newUser.surnames + ", " + newUser.name;
          users_partial.push(newUser);
        })
      );
      let sortedUsers = users_partial.sort((a, b) => {
        // Ordenar por companyName
        const companyNameA = this.normalize(a["companyName"]);
        const companyNameB = this.normalize(b["companyName"]);
        if (companyNameA > companyNameB) return 1;
        if (companyNameA < companyNameB) return -1;

        // Si companyName es igual, ordenar por fullName
        const fullNameA = this.normalize(a["fullName"]);
        const fullNameB = this.normalize(b["fullName"]);
        if (fullNameA > fullNameB) return 1;
        if (fullNameA < fullNameB) return -1;

        // Si companyName y fullName son iguales, no hay cambio en el orden
        return 0;
      });
      this.users = sortedUsers;
    },
    async getLoggedUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    editUser(userId) {
      this.showEditUserPopup = true;
      this.selectedUser = this.users.find(user => user.userId === userId);
      this.newLoginName = this.selectedUser.loginName;
      this.newName = this.selectedUser.name;
      this.newSurnames = this.selectedUser.surnames;
      this.newEmail = this.selectedUser.email;
      this.newEntryDate = dayjs(this.selectedUser.entryDate).format("DD/MM/YYYY");
      if (this.selectedUser.outDate) {
        this.newOutDate = dayjs(this.selectedUser.outDate).format("DD/MM/YYYY");
      } else {
        this.newOutDate = "";
      }
    },
    closeEditUser() {
      this.showEditUserPopup = false;
      this.selectedUser = null;
    },
    async updateUser() {
      this.selectedUser.loginName = this.newLoginName;
      this.selectedUser.name = this.newName;
      this.selectedUser.surnames = this.newSurnames;
      this.selectedUser.email = this.newEmail;
      this.selectedUser.entryDate = this.newEntryDate;
      this.selectedUser.outDate = this.newOutDate;
      await users.edit(this.selectedUser);
      this.closeEditUser();
      this.getData();
    },
    handleItemSelected(key) {
      this.newCompanyId = key;
    },
    async fetchCompanies() {
      let allCompanies = await companies.getAllCompanies();
      const allCompaniesData = allCompanies.data.reduce((acc, curr) => {
        acc[curr.companyId] = curr.name;
        return acc;
      }, {});
      this.dropdownOptions = allCompaniesData;
    },
    companyOptions() {
      return this.dropdownOptions;
    },
    addUser() {
      this.showAddUserPopup = true;
      this.newLoginName = "";
      this.newEmail = "";
      this.newPassword = "";
      this.newCompanyId = "";
      this.newName = "";
      this.newSurnames = "";
      this.newEntryDate = "";
      this.newIsAdminHolidays = false;
      this.newIsSAHolidays = false;
      this.newIsAdminCatering = false;
      this.newIsSACatering = false;
    },
    closeAddUser() {
      this.showAddUserPopup = false;
    },
    async insertUser() {
      this.errorMessage = "";
      if (this.newLoginName == "") {
        this.errorMessage = "Field Login Name is empty";
        return;
      }
      if (this.newEmail == "") {
        this.errorMessage = "Field Email is empty";
        return;
      }
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexEmail.test(this.newEmail)) {
        this.errorMessage = "Email format incorrect";
        return;
      }
      if (this.newPassword == "") {
        this.errorMessage = "Field Password is empty";
        return;
      }
      const regexPassword = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
      if (!regexPassword.test(this.newPassword) && this.newPassword.length < 8) {
        this.errorMessage = "Password musta have a letter, a number and at least eight characters";
        return;
      }
      if (this.newName == "") {
        this.errorMessage = "Field Name is empty";
        return;
      }
      if (this.newSurnames == "") {
        this.errorMessage = "Field Surname is empty";
        return;
      }
      if (this.newEntryDate == "") {
        this.errorMessage = "Field Entry Date is empty";
        return;
      }
      let newUser = {
        loginName: this.newLoginName,
        email: this.newEmail,
        password: this.newPassword,
        companyId: this.newCompanyId,
        name: this.newName,
        surnames: this.newSurnames,
        isAdminHolidays: this.newIsAdminHolidays,
        isSAHolidays: this.newIsSAHolidays,
        isAdminCatering: this.newIsAdminCatering,
        isSACatering: this.newIsSACatering,
        entryDate: this.newEntryDate
      };
      await users.post(newUser);
      this.closeAddUser();
      this.getData();
    },
    async deleteUser() {
      await users.delete(this.userIdToDelete);
      this.userIdToDelete = null;
      this.showConfirmationDelete = false;
      this.getData();
    },
    async deleteUserConfirm(userId, display) {
      this.userIdToDelete = userId;
      this.showConfirmationDelete = display;
    },
    firstCurrentYearBusinessDay() {
      const now = new Date();
      const firstCurrentYearBusinessDay = new Date(now.getFullYear(), 0);
      return dayjs(firstCurrentYearBusinessDay).businessDaysInMonth()[0].format("DD/MM/YYYY");
    },
    today() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return dayjs(today).format("DD/MM/YYYY");
    },
    async checkUser() {
      this.loggedUser = await this.getLoggedUser();
      this.hasPermissionAdmin = this.loggedUser.isAdminHolidays || this.loggedUser.isSAHolidays;
      if (!this.hasPermissionAdmin) {
        this.$router.push({ name: "userinfo" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header-container {
  margin-top: 2rem;
}
.sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.users-container {
  width: 65%;
  margin: 5rem auto;
  th {
    text-align: left;
    padding-bottom: 8px;
    &:last-child {
      width: 350px;
    }
  }
  tr td {
    padding: 8px 0;
  }
}
.users {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.users li {
  display: flex;
  justify-content: space-between;
}
.popup-input-date {
  width: 100%;
  background: none;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  margin-bottom: 2rem;
}
</style>
