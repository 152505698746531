<template>
  <div>
    <div class="sub-header-container">
      <div class="sub-header">
        <div class="sub-header-buttons">
          <ets-alert v-if="manageUserId && managedUserName" type="warning"
            >Estás modificando el catering de {{ managedUserName }}</ets-alert
          >
        </div>
      </div>
    </div>
    <div class="calendar">
      <ets-calendar-annual
        v-if="dates"
        :dates="dates"
        :end-date="endDate"
        @prev="refreshDates"
        @today="todayDates"
        @next="refreshDates"
        :clickable-from="firstClickableDate"
        @date-clicked="dateClickHandler"
        :legend="legend"
        :prev-text="prevText"
        :today-text="todayText"
        :next-text="nextText"
        :current-text="currentText"
      />
    </div>
    <ets-popup ref="editDatePopup" :title="selectedDate | etsdate" v-if="showEditDatePopup" @accept="editDate" @close="closeEditDate">
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Tipo de menú</label>
          <select v-model="newMenuTypeId" class="popup-select">
            <option value="1">Primero y segundo normales</option>
            <option value="2">Primero y segundo especiales</option>
            <option value="3">Primero normal y segundo especial</option>
            <option value="4">Primero especial y segundo normal</option>
          </select>
          <br />
          <label class="popup-label">Primer plato normal</label>
          <input type="text" v-model="firstCourseNormal" readonly class="popup-input" />
          <label class="popup-label">Segundo plato normal</label>
          <input type="text" v-model="secondCourseNormal" readonly class="popup-input" />
          <label class="popup-label">Postre normal</label>
          <input type="text" v-model="dessertNormal" readonly class="popup-input" />
        </div>
        <div class="popup-col">
          <label class="popup-label">Número de menús</label>
          <input v-model="newMenuNumber" type="number" />
          <br />
          <label class="popup-label">Primer plato especial</label>
          <input type="text" v-model="firstCourseSpecial" readonly class="popup-input" />
          <label class="popup-label">Segundo plato especial</label>
          <input type="text" v-model="secondCourseSpecial" readonly class="popup-input" />
          <label class="popup-label">Postre especial</label>
          <input type="text" v-model="dessertSpecial" readonly class="popup-input" />
        </div>
      </div>
      <template slot="footer">
        <ets-button text="Anular menú" mode="error" @click="deleteDate" />
      </template>
    </ets-popup>
    <ets-popup
      v-if="this.showCateringDeadline"
      title="Hora Límite"
      @close="showCateringDeadline = false"
      @accept="showCateringDeadline = false"
    >
      <div>No es posible apuntarse o borrarse del catering después de las 18:00.</div>
    </ets-popup>
    <ets-popup v-if="this.showForbiddenDate" title="Prohibido" @close="showForbiddenDate = false" @accept="showForbiddenDate = false">
      <div>
        <label class="popup-label">No hay catering en las siguientes fechas:</label>
        <label class="popup-label">- Festivos</label>
        <label class="popup-label">- Mes de agosto</label>
        <label class="popup-label">- Viernes comprendidos entre el 15 de junio y el 15 de septiembre</label>
      </div>
    </ets-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import catering from "@/logic/catering";
import tokens from "@/logic/tokens";
import users from "@/logic/users";
import holidays from "@/logic/holidays";

export default {
  data: () => ({
    beginDate: dayjs().startOf("year").toDate(),
    today: new Date(),
    endDate: dayjs().endOf("year").toDate(),
    dateToAdd: null,
    showEditDatePopup: false,
    selectedDate: null,
    historicalMeals: [],
    futureMeals: [],
    pdfs: [],
    firstClickableDate: dayjs(new Date()).add(1, "day").toDate(),
    showCateringDeadline: false,
    managedUserName: "",
    nationalHolidays: [],
    showForbiddenDate: false,
    firstCourseNormal: "",
    secondCourseNormal: "",
    dessertNormal: "",
    firstCourseSpecial: "",
    secondCourseSpecial: "",
    dessertSpecial: ""
  }),
  computed: {
    ...mapGetters(["getUserLogged"]),
    dates() {
      let a = this.historicalMeals
        .map(x => ({
          date: new Date(x.date),
          cssClass: this.getCssClass(x.menuTypeId, true)
        }))
        .concat(
          this.futureMeals.map(x => ({
            date: new Date(x.date),
            cssClass: this.getCssClass(x.menuTypeId, false)
          }))
        )
        .concat(
          this.nationalHolidays.map(x => ({
            date: new Date(x.date),
            cssClass: "national_holiday"
          }))
        );
      return a;
    },
    legend() {
      return [
        { color: "#99ccee", name: "Menú normal. No modificable." },
        { color: "#99dd88", name: "Menú especial. No modificable." },
        { color: "#5b934c", name: "Menú primero normal segundo especial. No modificable." },
        { color: "#ddd97e", name: "Menú primero especial segundo normal. No modificable." },
        { color: "#c44e58", name: "Festivo. No modificable." },
        { border: "#99ccee", name: "Menú normal. Modificable." },
        { border: "#99dd88", name: "Menú especial. Modificable." },
        { border: "#c44e58", name: "No hay servicio de catering" }
      ];
    },
    noSelectedDays() {
      return this.pattern.byWeekday ? this.pattern.byWeekday.length === 0 : true;
    },
    endDateHistorical() {
      return this.endDate.getFullYear() === new Date().getFullYear() ? new Date() : this.endDate;
    },
    beginDateFuture() {
      return this.endDate.getFullYear() > new Date().getFullYear() ? this.beginDate : this.endDateHistorical;
    },
    prevText() {
      return dayjs(this.endDate).subtract(1, "year").year().toString();
    },
    nextText() {
      return dayjs(this.endDate).add(1, "year").year().toString();
    },
    currentText() {
      return dayjs(this.endDate).year().toString();
    },
    todayText() {
      return "Año actual";
    },
    userId() {
      return tokens.getDecodedToken().userId;
    },
    manageUserId() {
      return this.$route.query.manageUserId;
    },
    tomorrow() {
      return this.today.add(1, "day").format("YYYY-MM-DD");
    }
  },
  methods: {
    isFridayForbidden(date) {
      const currentYear = dayjs(date).year();
      const startDate = dayjs(`${currentYear}-06-15`);
      const endDate = dayjs(`${currentYear}-09-15`);
      const inRange = dayjs(date).isAfter(startDate.subtract(1, "day")) && dayjs(date).isBefore(endDate.add(1, "day"));
      return dayjs(date).day() === 5 && inRange;
    },
    async dateClickHandler(date) {
      let isNationalHoliday = await holidays.checkIsNationalHoliday(dayjs(date).format("YYYYMMDD"));
      let isAugust = dayjs(date).month() + 1 == 8;
      if (isNationalHoliday.data || isAugust || this.isFridayForbidden(date)) {
        this.showForbiddenDate = true;
      } else {
        let currentTime = dayjs();
        const sixPM = dayjs().set("hour", 18).set("minute", 0).set("second", 0);
        let isAfterSix = dayjs(date).isSame(dayjs(new Date()).add(1, "day").startOf("day").toDate()) && currentTime.isAfter(sixPM);
        if (!this.hasPermissionAdmin && isAfterSix) {
          this.showCateringDeadline = true;
        } else {
          let found = null;
          if (date < this.today) {
            found = this.historicalMeals.find(d => new Date(d.date).getTime() === new Date(date).getTime());
          } else {
            found = this.futureMeals.find(d => new Date(d.date).getTime() === new Date(date).getTime());
          }
          if (found) {
            this.showEditDatePopup = true;
            this.newMenuNumber = found.number ? found.number : 1;
            this.newMenuTypeId = found.menuTypeId ? found.menuTypeId : 1;
            this.selectedDate = date;
            this.getMenuList(date);
          } else {
            this.addDate(dayjs(date).format("YYYYMMDD"));
          }
        }
      }
    },
    todayDates() {
      this.beginDate = dayjs().startOf("year").toDate();
      this.today = new Date();
      this.endDate = dayjs().endOf("year").toDate();

      this.getMeals();
      this.getFutureMeals();
    },
    refreshDates(date) {
      this.beginDate = dayjs(date).subtract(1, "year").toDate();
      this.endDate = date;
      if (dayjs(date).year() <= dayjs().year()) {
        this.getMeals();
      }
      if (dayjs(date).year() >= dayjs().year()) {
        this.getMeals();
        this.getFutureMeals();
      }
      this.getNationalHolidays();
    },
    editDate() {
      this.updateMealInfo(dayjs(this.selectedDate).format("YYYYMMDD"), this.newMenuNumber, this.newMenuTypeId);
      this.showEditDatePopup = false;
    },
    deleteDate() {
      this.deleteMealDate(this.selectedDate);
      this.showEditDatePopup = false;
    },
    async editFutureDates(date, menuTypeId, menuNumber) {
      let user = this.effectiveUser();
      await catering.edit(user, date, menuTypeId, menuNumber, false);
    },
    async addFutureDates(date, menuTypeId, menuNumber) {
      let user = this.effectiveUser();
      await catering.post(user, date, menuTypeId, menuNumber, false);
    },
    async deleteFutureDates(date) {
      let user = this.effectiveUser();
      await catering.delete(user, dayjs(date).format("YYYYMMDD"));
    },
    updateMealInfo(date, menuNumber, menuTypeId) {
      try {
        this.editFutureDates(date, menuTypeId, menuNumber).then(() => {
          this.getMeals();
          this.getFutureMeals();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    deleteMealDate(date) {
      try {
        this.deleteFutureDates(date).then(() => {
          this.getMeals();
          this.getFutureMeals();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    addDate(date) {
      try {
        this.addFutureDates(date, 1, 1).then(() => {
          this.getMeals();
          this.getFutureMeals();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    closeEditDate() {
      this.showEditDatePopup = false;
    },
    effectiveUser() {
      if (this.manageUserId != null) {
        return this.manageUserId;
      } else {
        return this.userId;
      }
    },
    async getMeals() {
      const start = dayjs(this.beginDate).format("YYYYMMDD");
      const end = dayjs(this.endDateHistorical).format("YYYYMMDD");
      try {
        let user = this.effectiveUser();
        let response = await catering.getCateringInfoById(user, start, end);
        this.historicalMeals = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getFutureMeals() {
      let user = this.effectiveUser();
      const start = dayjs(this.beginDateFuture).format("YYYYMMDD");
      const end = dayjs(this.endDate).format("YYYYMMDD");
      try {
        let response = await catering.getCateringInfoById(user, start, end);
        this.futureMeals = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getMenuList(date) {
      let response = await catering.getMenuList(dayjs(date).format("YYYYMMDD"));
      if (response.data.length !== 0) {
        this.firstCourseNormal = response.data[0]["firstCourseNormal"];
        this.secondCourseNormal = response.data[0]["secondCourseNormal"];
        this.dessertNormal = response.data[0]["dessertNormal"];
        this.firstCourseSpecial = response.data[0]["firstCourseSpecial"];
        this.secondCourseSpecial = response.data[0]["secondCourseSpecial"];
        this.dessertSpecial = response.data[0]["dessertSpecial"];
      } else {
        this.firstCourseNormal = "";
        this.secondCourseNormal = "";
        this.dessertNormal = "";
        this.firstCourseSpecial = "";
        this.secondCourseSpecial = "";
        this.dessertspecial = "";
      }
    },
    getCssClass(menuTypeId, historical) {
      if (historical) {
        switch (menuTypeId) {
          case 1:
            return "historical_regular_meal";
          case 2:
            return "historical_special_meal";
          case 3:
            return "historical_normal_special_meal";
          case 4:
            return "historical_special_normal_meal";
          default:
            return "";
        }
      } else {
        switch (menuTypeId) {
          case 1:
            return "future_regular_meal";
          case 2:
            return "future_special_meal";
          case 3:
            return "future_normal_special_meal";
          case 4:
            return "future_special_normal_meal";
          default:
            return "";
        }
      }
    },
    async getNationalHolidays() {
      try {
        let year = dayjs(this.endDate).year().toString();
        let response = await holidays.getNationalHolidays(year);
        this.nationalHolidays = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async checkUser() {
      let logged_user = await users.getUser(this.userId);
      this.hasPermissionAdmin = logged_user.data.isAdminCatering || logged_user.data.isSACatering;
      if (this.hasPermissionAdmin) {
        this.firstClickableDate = null;
      }
      if (this.manageUserId != null) {
        if (!logged_user.data.isAdminCatering && !logged_user.data.isSACatering) {
          this.$router.push({ name: "userinfo" });
        }
      }
    },
    async fetchManagedUser() {
      if (this.manageUserId != null) {
        const response = await users.getUser(this.manageUserId);
        this.managedUserName = response.data.name + " " + response.data.surnames;
      }
    }
  },
  created() {
    this.getMeals();
    this.getFutureMeals();
    this.checkUser();
    this.fetchManagedUser();
    this.getNationalHolidays();
  }
};
</script>

<style lang="scss" scoped>
.calendar ::v-deep {
  .historical_regular_meal {
    box-shadow: 0 0 0 2px #99ccee;
    background-color: #99ccee !important;
    color: white;
  }
  .historical_special_meal {
    box-shadow: 0 0 0 2px #99dd88;
    background-color: #99dd88 !important;
    color: white;
  }
  .historical_normal_special_meal {
    box-shadow: 0 0 0 2px #5b934c;
    background-color: #5b934c !important;
    color: white;
  }
  .historical_special_normal_meal {
    box-shadow: 0 0 0 2px #ddd97e;
    background-color: #ddd97e !important;
    color: white;
  }
  .future_regular_meal {
    box-shadow: 0 0 0 2px #99ccee !important;
    color: black;
  }
  .future_special_meal {
    box-shadow: 0 0 0 2px #99dd88 !important;
    color: black;
  }
  .future_normal_special_meal {
    box-shadow: 0 0 0 2px #5b934c !important;
    color: black;
  }
  .future_special_normal_meal {
    box-shadow: 0 0 0 2px #ddd97e !important;
    color: black;
  }
  .no_catering {
    box-shadow: 0 0 0 2px #c44e58 !important;
    color: black;
  }
  .calendar-wrapper {
    justify-content: space-around;
  }
  .national_holiday {
    box-shadow: 0 0 0 2px #eb1404;
    background-color: #eb1404 !important;
    color: white;
  }
  .today-button {
    @include mobile-only {
      position: relative;
      margin-top: 2rem;
    }
  }
}
.popup-container {
  display: inline-flex;
}
.popup-col {
  padding: 0.5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}
.popup-label {
  margin-top: 18px;
  margin-bottom: 18px;
  display: block;
}
.popup-select {
  width: 100%;
}
.calendar {
  margin: 0px auto;
  @include desktop {
    width: 75%;
  }
}
.sub-header-container {
  margin-top: 2rem;
}
.sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.sub-header-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
