<template>
  <div class="page">
    <header class="header">
      <a href="/">
        <img class="logo" src="logo.svg" alt="Logo ETS Serenitas" />
      </a>
      <nav class="nav">
        <ul>
          <li><a href="#why">Por qué</a></li>
          <li><a href="#what">Qué</a></li>
          <li><a href="#how">Cómo</a></li>
          <li><a href="#who">Quién</a></li>
          <li><a href="#for-whom">Para quién</a></li>
        </ul>
      </nav>
      <router-link to="/login">
        <ets-button mode="success" text="Inicia sesión" />
      </router-link>
    </header>
    <main>
      <section id="main">
        <img class="secondary-logo" src="logo.svg" alt="logo secundario" />
        <h1><strong>"</strong><small> El aprecio por las</small><br /><strong>Cosas bien hechas</strong><strong>"</strong></h1>
      </section>
      <section id="why">
        <div class="section-container section-grid">
          <h1 class="section-title"><small>El acento, en la</small><br /><strong>Confianza</strong></h1>
          <div class="section-content">
            <p>
              ETS Serenitas lleva más de 25 años prestando servicios de oficina de familia a la familia Bolívar (propietaria del grupo
              empresarial Expert Timing Systems) y a todo su ecosistema de empresas.
            </p>
            <p>
              <strong
                >Ofrecemos nuestros servicios también a aquellos clientes que compartan nuestro aprecio por las cosas bien hechas</strong
              >, poniendo nuestra experiencia al servicio de sus compañías o patrimonios familiares.
            </p>
          </div>
        </div>
      </section>
      <section id="what">
        <div class="section-container">
          <h1 class="section-title"><small>El acento, en la</small><br /><strong>Excelencia</strong></h1>
          <div class="section-content">
            <ul class="what-areas">
              <li>
                <h3>Administración y RRHH</h3>
                <p>
                  Incluye todos los servicios relacionados con facturación, alquileres, contratos, oficinas, gestión de empleados, trámites
                  personales.
                </p>
              </li>
              <li>
                <h3>Contabilidad</h3>
                <p>Dado el carácter sensible de la información a tratar, se realiza la contabilidad personal y empresarial internamente.</p>
              </li>
              <li>
                <h3>Finanzas</h3>
                <p>
                  Gestión de tesorería para empresas y patrimonios familiares que faciliten a nuestros clientes la toma de decisiones de
                  inversión y que aseguren una gestión correcta del riesgo de liquidez.
                </p>
              </li>
              <li>
                <h3>Fiscalidad</h3>
                <p>
                  Colaboramos con equipos de prestigio internacional que nos permiten ofrecer a nuestros clientes un asesoramiento fiscal de
                  primer nivel.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="how">
        <div class="section-container section-grid">
          <h1 class="section-title"><small>El acento, en los</small><br /><strong>Detalles</strong></h1>
          <div class="section-content">
            <p>
              <strong>Nuestra misión es dar soluciones</strong> que permitan a nuestros clientes centrarse en los aspectos más estratégicos
              de sus compañías resolviendo de forma solvente, continuada y profesional todo aquello relacionado con áreas más operativas en
              la administración diaria de sus empresas o patrimonios.
            </p>
            <p>
              Por el carácter eminentemente familiar que nos caracteriza, ETS Serenitas no limita su trabajo a una lista tasada de
              servicios, sino que ofrece una
              <strong>administración integral del patrimonio personal y empresarial de nuestros clientes</strong>.
            </p>
          </div>
        </div>
      </section>
      <section id="who">
        <h1 class="section-title"><small>El acento, en las</small><br /><strong>Personas</strong></h1>
        <div class="members">
          <landing-member v-for="(member, i) in members" :key="i" :imageUrl="member.imageUrl" :name="member.name" :role="member.role" />
        </div>
      </section>
      <section id="for-whom">
        <div class="section-container section-grid">
          <h1 class="section-title"><small>El acento, en la</small><br /><strong>Cercanía</strong></h1>
          <div class="section-content">
            <p>
              Para mantener un alto estándar de calidad y conexión con nuestros clientes,
              <strong>valoramos mucho las recomendaciones de quienes ya nos conocen</strong>. A través de estas recomendaciones, no solo
              aseguramos mantener la calidad de nuestro servicio, sino también fortalecemos los lazos de confianza y familiaridad dentro de
              nuestra comunidad empresarial.
            </p>
            <p>
              Sin embargo, estamos abiertos a conocer nuevas personas interesadas en nuestros servicios y estamos dispuestos a explorar cómo
              podemos satisfacer sus necesidades de la mejor manera posible.
            </p>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <ul>
        <li>
          <a href="tel:+34913572471" target="_blank">
            <img src="@/assets/icons/phone.svg" alt="phone" />
            <span>+34 91 357 24 71</span>
          </a>
        </li>
        <li>
          <a href="mailto:info@etsfactory.com">
            <img src="@/assets/icons/mail.svg" alt="phone" />
            <span>info@etsfactory.com</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/etsfactory" target="_blank">
            <img src="@/assets/icons/linkedin.svg" alt="phone" />
            <span>LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/EtsFactory" target="_blank">
            <img src="@/assets/icons/twitter.svg" alt="phone" />
            <span>Twitter</span>
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import LandingMember from "@/components/LandingMember.vue";
import tokens from "@/logic/tokens";
export default {
  components: {
    LandingMember
  },
  data: () => ({
    members: [
      {
        imageUrl: require("@/assets/images/mem_mercedes.jpg"),
        name: "Mercedes Martínez Cunha",
        role: "Head of Human Resources & Administration"
      },
      {
        imageUrl: require("@/assets/images/mem_fernando.png"),
        name: "Fernando Bolívar Almela",
        role: "Partner"
      },
      {
        imageUrl: require("@/assets/images/mem_jorge.jpg"),
        name: "Jorge Bolívar Almela",
        role: "Partner"
      },
      {
        imageUrl: require("@/assets/images/mem_isabel.jpg"),
        name: "Isabel López Cano",
        role: "Human Resources & Administration"
      },
      {
        imageUrl: require("@/assets/images/mem_damaris.jpg"),
        name: "Dámaris García González",
        role: "Human Resources & Administration"
      }
    ]
  }),
  methods: {
    async checkAutoLogin() {
      let token = tokens.getDecodedToken();
      if (token != null) {
        this.$router.push({ name: "home" });
      }
    }
  },
  mounted() {
    this.checkAutoLogin();
  }
};
</script>

<style lang="scss" scoped>
section {
  padding: 4rem 2rem;
  display: grid;
  place-items: center;
  color: $color-primary;
}
h1 {
  font-family: "Playfair Display";
  font-size: 5rem;
  text-align: center;
  margin: 0;
}
h2 {
  margin-left: 800px;
}
small {
  font-weight: 400;
}
strong {
  font-weight: 900;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
.page {
  height: 100vh;
  overflow: auto;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  position: fixed;
  left: 0;
  right: 10px;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}
.logo {
  width: 150px;
}
.nav {
  @include font-base;
  display: none;
  font-size: 1.6rem;
  flex-grow: 1;
  ul {
    display: flex;
    justify-content: center;
  }
  li {
    padding: 0 1.5rem;
    text-align: center;
    white-space: nowrap;
    position: relative;
    &::after {
      content: "·";
      position: absolute;
      right: 0;
    }
    &:last-child::after {
      content: "";
    }
  }
  a {
    color: $color-primary;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }
}
.secondary-logo {
  display: none;
  width: 700px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.button {
  background: $color-primary !important;
}
.section-container {
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;
}
.section-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
  gap: 4rem;
}
.section-content {
  font-family: Montserrat;
  font-size: 1.6rem;
  line-height: 1.65;
  p {
    text-align: justify;
  }
}
#main {
  height: 100vh;
  position: relative;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("../assets/images/landing.jpg");
  background-size: cover;
  background-position: 100% 100px;
}
#what {
  .what-areas {
    margin: 8rem auto 0;
    > li h3 {
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}
#who {
  .members {
    margin-top: 8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
    max-width: 1800px;
  }
}
#why,
#how,
#for-whom {
  background-color: transparentize($color-secondary, 0.85);
}
footer {
  padding: 10rem 0;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    > li {
      padding: 0 3rem;
      position: relative;
      a {
        display: flex;
        gap: 1rem;
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 9rem;
  }
  section {
    padding: 16rem 8rem;
  }
  .nav {
    display: block;
  }
  .section-content {
    font-size: 2rem;
  }
  footer {
    ul {
      flex-direction: row;
      gap: 0;
      > li + li::before {
        content: "·";
        position: absolute;
        left: 0;
      }
    }
  }
  #what .what-areas {
    margin-top: 8rem;
  }
  #who .members {
    margin-top: 16rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .nav li {
    padding: 0 3rem;
  }
  #main h1 {
    position: absolute;
    top: 25%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .logo {
    width: 230px;
  }
  .secondary-logo {
    display: none;
    bottom: 35%;
  }
  .nav li {
    padding: 0 5rem;
  }
  #what .what-areas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem 10rem;
  }
  .section-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 4rem;
  }
}
@media only screen and (min-width: 1500px) {
  .secondary-logo {
    bottom: 50%;
  }
}
</style>
